import { AxiosResponse } from "axios";
import { PartnerInfo } from "../models/PartnerInfo.js";
import instance from "./axios.js";

interface PostRefundCardRequest {
  sid: string;
  crnum: string;
  crholder: string;
  crmonth: string;
  cryear: string;
  ccv: string;
}

interface PostRefundBankRequest {
  sid: string;
  iname: string;
  acnum: string;
  acholder: string;
  transit: string;
  bankaddr: string;
  billaddr: string;
}

/**
 * Verifies sim card number.
 * @param simCardNum
 * @returns
 */
const verifySimCard = (simCardNum: string) =>
  instance.post(`/Activation/VerifySimcard?simnum=${simCardNum}`);

/**
 * Gets a list of countries for activation.
 * @returns
 */
const getCountryList = () => instance.get("/Activation/GetCountries");

// TODO: THIS JUST THROWS 500 error when attempting to login. Doesn't appear to do anything anyway. Remove?
// /**
//  * Logs in with test credentials? Very insecure.
//  * @returns
//  */
// const login = () => instance.post("/LoginRegistration/Login1", { Email: "tomokoyamashita", Password: "testtest", GoogleToken: "", DeviceToken: "", });

/**
 * Gets shipping options by country id.
 * @param countryId
 * @returns
 */
const getShippingOptions = (countryId: number) =>
  instance.get(`/Activation/GetShippingOptions?countryId=${countryId}`);

/**
 * Sends an email.
 * @param customerName
 * @param sendfromPage
 * @param sendToGroup
 * @param content
 */
const sendEmail = (
  customerName: string,
  sendfromPage: string,
  sendToGroup: string,
  content: string
) =>
  instance.post("/help/SendEmail", {
    customerName,
    sendfromPage,
    sendToGroup,
    content,
  });

const sendEmail2 = (partnerInfo: PartnerInfo) =>
  instance.post("/help/SendEmail2", partnerInfo);

/**
 * Retrieves information about a referring cnum.
 * @param cnum
 */
const verifyReferral = (cnum: string) => {
  return instance.get(`/Activation/VerifyReferral?cnum=${cnum}`);
};

/**
 * Gets plans.
 * @param carrier
 * @param promo_code
 * @param currency
 * @param planType
 */
const getPlans = (
  carrier: string,
  promo_code: string,
  currency: string,
  planType: string
) => {
  let params;
  if (
    window.location.href.includes("http://localhost:8000/") ||
    window.location.href.includes("https://testhp2021.gophonebox.com/")
  ) {
    params = { currency, carrier, promocode: promo_code, testenv: "yes" };
  } else {
    params = { currency, carrier, promocode: promo_code };
  }
  // add promo_code newcomer2503
  if (!params?.promocode?.trim()) {
    params = { ...params, promocode: "newcomer2503" };
  }

  const url =
    planType === "Prepaid"
      ? "/Activation/PrepaidPlans"
      : "/Activation/PostpaidPlans";
  return instance.post(url, null, { params });
};

/**
 * Gets promo codes for partner.
 * @param promoCode
 */
const getPartnerPromoCodeAll = (bn: string) => {
  if (bn == "4230") {
    // reject promise
    return new Promise((_, reject) => {
      reject();
    });
  }
  return instance.get(`/Activation/GetPartnerPromocodeAll?bizId=${bn}`);
};

/**
 * Unsubscribes user from email
 * @param secret // encrypted cnum and email provided by email link
 */
const unsubscribeEmail = (secret: string, consent = false) => {
  let url = `/Activation/UnsubscribeEmail?secret=${secret}`;
  if (consent) {
    url = url + "&consent=yes";
  }
  return instance.post(url);
};

// stage one of
const getPortInStatus = (uid: string) => {
  let url = `/Account/GetPortInStatus`;

  instance.interceptors.request.use(async (config) => {
    try {
      config.headers["uid"] = uid;
      return config;
    } catch (e) {
      console.error(e);
    }
  });

  return instance.get(url);
};

// stage 2 of internal migration
const getPortInConsentStatus = (rid: string) => {
  let url = `/Account/GetPortInConsentStatus`;

  instance.interceptors.request.use(async (config) => {
    try {
      config.headers["rid"] = rid;
      return config;
    } catch (e) {
      console.error(e);
    }
  });

  return instance.get(url);
};

const submitPortInRequest = (uid: string, portInRequest) => {
  let url = `/Account/SubmitPortInRequest`;

  instance.interceptors.request.use(async (config) => {
    try {
      config.headers["uid"] = uid;
      return config;
    } catch (e) {
      console.error(e);
    }
  });

  return instance.post(url, portInRequest);
};

// part 2 of submitting consent for internal migration.
const submitPortInConsentRequest = (rid: string, portInConsentRequest) => {
  let url = `/Account/SubmitPortInConsentRequest`;

  instance.interceptors.request.use(async (config) => {
    try {
      config.headers["rid"] = rid;
      return config;
    } catch (e) {
      console.error(e);
    }
  });

  return instance.post(url, portInConsentRequest);
};

/**
 * Gets the information of the client who needs a refund.
 * @param sid the user's encrypted cnum
 */
const getRefundClient = (sid: string) => {
  let url = `/Intraservice/GetRefundClient?sid=${sid}`;

  return instance.get(url);
};

/**
 * Delivers information for a refund to a bank account
 * @param postRefundBankRequest
 */
const postRefundBank = (postRefundBankRequest: PostRefundBankRequest) => {
  return instance.post("/Intraservice/PostRefundBank", postRefundBankRequest);
};

/**
 * Delivers information for a refund to a credit card
 * @param postRefundCardRequest
 */
const postRefundCard = (postRefundCardRequest: PostRefundCardRequest) => {
  return instance.post("/Intraservice/PostRefundCard", postRefundCardRequest);
};

/**
 * Gets a list of available financial institutions for refund page.
 */
const getRefundFinancialInstitutions = () =>
  instance.get("/Intraservice/GetRefundFIs");

const submitSurvey = (
  TicketId: string,
  Satification: string,
  Comment: string
) => instance.post("/Tickets/Survey", { TicketId, Satification, Comment });

const updateShippingStatus = (rid: string, received: string) =>
  instance.post("/Account/ShippingStatusUpdate", { rid, received });

const submitRefundRequest = (uid: string) => {
  let encodedUID = encodeURIComponent(uid);
  return instance.post("/Account/SubmitRefundRequest?uid=" + encodedUID);
};

const reportCustomerConcern = (
  data: Record<string, string | boolean>
): Promise<
  AxiosResponse<{
    IsSuccess: boolean;
    Message: string;
  }>
> => {
  return instance.post("/Activation/ReportCustomerConcern", data);
};

const validatePortinAccountNumber = (
  accountNumber: string
): Promise<
  AxiosResponse<{
    IsSuccess: boolean;
    Message: string;
  }>
> =>
  instance.get(
    `/Activation/ValidatePortinAccountNumber?accountNumber=${accountNumber}`
  );

const checkIfAlreadyPhoneBoxCustomer = (
  phoneNumber: string
): Promise<AxiosResponse<boolean>> =>
  instance.get(
    `/Activation/CheckIfAlreadyPhoneBoxCustomer?phoneNumber=${phoneNumber}`
  );

const sendOneTimePaymentLink = (
  bizId: number,
  refNo: number,
  acType: "postpaid" | "prepaid"
): Promise<
  AxiosResponse<{
    success: boolean;
    message: string;
    error: string;
    data: null;
  }>
> =>
  instance.post(
    `/Activation/PostReminderEmail?bizId=${bizId}&refNo=${refNo}&acType=${acType}`
  );

export const api = {
  verifySimCard: verifySimCard,
  getCountryList: getCountryList,
  getShippingOptions: getShippingOptions,
  sendEmail: sendEmail,
  sendEmail2: sendEmail2,
  getPlans: getPlans,
  getPartnerPromoCodeAll: getPartnerPromoCodeAll,
  verifyReferral: verifyReferral,
  unsubscribeEmail: unsubscribeEmail,
  submitSurvey: submitSurvey,
  updateShippingStatus: updateShippingStatus,
  getPortInStatus: getPortInStatus,
  submitPortInRequest: submitPortInRequest,
  getRefundClient: getRefundClient,
  getRefundFinancialInstitutions: getRefundFinancialInstitutions,
  postRefundBank: postRefundBank,
  postRefundCard: postRefundCard,
  getPortInConsentStatus: getPortInConsentStatus,
  submitPortInConsentRequest: submitPortInConsentRequest,
  submitRefundRequest: submitRefundRequest,
  reportCustomerConcern: reportCustomerConcern,
  validatePortinAccountNumber: validatePortinAccountNumber,
  checkIfAlreadyPhoneBoxCustomer: checkIfAlreadyPhoneBoxCustomer,
  sendOneTimePaymentLink: sendOneTimePaymentLink,
};
